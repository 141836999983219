<script setup lang="ts">
import * as _ from 'lodash-es';
import { computed, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { EventData } from '@repo/core/models';
import { ResourceConfig } from '@repo/core/resources';
import { EventConstants, MessageConstants } from '@repo/core/constants';
import { useDxEventBus, useDxMessage } from '@repo/vue/utils';
import { useAuthStore } from '@repo/vue/stores';
import { useDxState } from '@/utils';
import { useRootStore } from '@/stores';
import { useEventStore } from '@/stores';

// computed
const target = computed(() => window.parent);

// utils
const dxState = useDxState();
const dxEventBus = useDxEventBus();
const dxMessage = useDxMessage(target, ResourceConfig.webAngularUrl);

// stores
const authStore = useAuthStore();
const rootStore = useRootStore();
const eventStore = useEventStore();

// state
const { eventData } = storeToRefs(eventStore);
const { userProfileId, practiceId, clientId, tabId, financialYearId, supportAccessRequestId } = storeToRefs(rootStore);

// events
const eventBusListener = (event: string, payload: any) => {
  switch (event) {
    case EventConstants.reloadClientMenu:
    case EventConstants.financialYearsChanged:
    case EventConstants.selectedYearChanged:
    case EventConstants.accountLinkComplete:
      sendMessage(event);
      break;
  }
};
dxEventBus.on(eventBusListener);

// messages
const onMessageEvent = async (e: MessageEvent) => {
  if (e.origin !== ResourceConfig.webAngularUrl) return;
  console.log('vue:onMessageEvent', e);

  // data
  const event = e.data.event;
  const page = e.data.page;
  const tabId = e.data.tabId;
  const userProfileId = e.data.userProfileId;
  const accessToken = e.data.accessToken;
  const financialYearId = e.data.financialYearId;
  const expires = e.data.expires;

  // headers
  const clientId = e.data.headers.ClientId;
  const practiceId = e.data.headers.PracticeId;
  const supportAccessRequestId = e.data.headers.SupportAccessRequestId;

  const eventData: EventData = {
    event: event,
    page: page,
    tabId: tabId,
    accessToken: accessToken,
    expires: expires,
    userProfileId: userProfileId,
    practiceId: practiceId,
    clientId: clientId,
    financialYearId: financialYearId,
    supportAccessRequestId: supportAccessRequestId
  };
  await processMessage(eventData);
};

// methods
const init = async () => {
  // clear state
  rootStore.clearCurrentTabs();

  // validate the token
  const isValid = await validateToken();
  if (!isValid) return;

  // send init message
  sendMessage(MessageConstants.init);
};

const validateToken = async () => {
  try {
    const token = await authStore.getTokenSilently();
    console.log('vue:token', token);
  } catch (error: any) {
    console.error('vue:error', error);
    if (error.message === 'login_required') {
      await dxState.login();
    }
    return false;
  }
  return true;
};

const processMessage = async (data: EventData) => {
  console.log('vue:processMessage', data);

  // check the session
  await authStore.checkSession();

  // update stores
  eventStore.$patch({
    eventData: data
  });
  rootStore.$patch({
    practiceId: _.toLower(data.practiceId),
    clientId: _.toLower(data.clientId),
    financialYearId: _.toLower(data.financialYearId),
    supportAccessRequestId: _.toLower(data.supportAccessRequestId)
  });

  // handle events
  switch (data.event) {
    case MessageConstants.login:
      await dxState.login();
      break;
    case MessageConstants.logout:
      await dxState.logout();
      break;
    case MessageConstants.loadPage:
      if (data.page) {
        await dxState.loadPage(data.page);
      }
      break;
    case MessageConstants.loadTab:
      if (data.page && data.tabId) {
        await dxState.loadTab(data.page, data.tabId);
      }
      break;
    case MessageConstants.removeTab:
      if (data.tabId) {
        await dxState.removeTab(data.tabId);
      }
      break;
    case MessageConstants.selectedYearChanged:
      if (data.financialYearId) {
        await dxState.selectedYearChanged(data.financialYearId);
      }
      break;
    default:
      console.error('Unsupported message type', data);
      break;
  }
};

const sendMessage = (event: string) => {
  const message = { ...eventData.value };
  message.event = event;
  message.tabId = tabId.value;
  message.userProfileId = userProfileId.value;
  message.practiceId = practiceId.value;
  message.clientId = clientId.value;
  message.financialYearId = financialYearId.value;
  message.supportAccessRequestId = supportAccessRequestId.value;
  dxMessage.sendMessage(message);
};

// watches
watch(dxMessage.event, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    onMessageEvent(newValue);
  }
});

// hooks
onMounted(async () => {
  await init();
});
</script>

<template />

<style scoped lang="scss" />
