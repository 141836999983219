import { computed } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { Client, FinancialYear, FinancialYearOverview, Practice, User } from '@repo/core/models';
import { useSessionStorage } from '@vueuse/core';
import { usePracticeStore } from '@/stores';
import { useClientStore } from '@/stores';
import { useUserStore } from '@/stores';
import { useFinancialStore } from '@/stores';
import { useRoute } from 'vue-router';
import { useTabStore } from '@/stores';
import { useAuthStore } from '@repo/vue/stores';

export const useRootStore = defineStore('root', () => {
  // utils
  const route = useRoute();

  // stores
  const authStore = useAuthStore();
  const practiceStore = usePracticeStore();
  const clientStore = useClientStore();
  const userStore = useUserStore();
  const financialStore = useFinancialStore();
  const tabStore = useTabStore();

  // state
  const practiceId = useSessionStorage<string>('practiceId', null);
  const clientId = useSessionStorage<string>('clientId', null);
  const supportAccessRequestId = useSessionStorage<string>('supportAccessRequestId', null);
  const financialYearId = useSessionStorage<string>('financialYearId', null);

  // getters
  const userProfileId = computed(() => authStore.user?.profile?.sub ?? 'system');
  const page = computed(() => route.name?.toString());
  const tabId = computed(() => route.query.tabId?.toString());
  const isClientSelected = computed(() => !!clientStore.currentClient?.id);
  const isClientReadonly = computed(() => clientStore.isReadOnly);

  const setPracticeId = (data: string | undefined) => {
    practiceId.value = data;
  };
  const setClientId = (data: string | undefined) => {
    clientId.value = data;
  };
  const setCurrentPractice = (data: Practice) => {
    practiceStore.currentPractice = data;
  };
  const setCurrentClient = (data: Client) => {
    clientStore.currentClient = data;
  };
  const setCurrentUser = (data: User) => {
    userStore.currentUser = data;
  };
  const clearCurrentPractice = () => {
    practiceId.value = null;
    practiceStore.currentPractice = <Practice>{};
  };
  const clearCurrentClient = () => {
    clientId.value = null;
    clientStore.currentClient = <Client>{};
  };
  const clearCurrentUser = () => {
    userStore.currentUser = <User>{};
  };
  const clearCurrentTicket = () => {
    supportAccessRequestId.value = null;
  };
  const clearCurrentYear = () => {
    financialStore.currentPeriod = {} as FinancialYear;
    financialStore.selectedPeriod = {} as FinancialYear;
    financialStore.financialYearOverview = {} as FinancialYearOverview;
  };
  const clearCurrentTabs = () => {
    tabStore.resetTabState();
  };

  const state = {
    userProfileId,
    clientId,
    practiceId,
    supportAccessRequestId,
    financialYearId
  };
  const getters = {
    page,
    tabId,
    isClientSelected,
    isClientReadonly
  };
  const actions = {
    setPracticeId,
    setClientId,
    setCurrentPractice,
    setCurrentClient,
    setCurrentUser,
    clearCurrentPractice,
    clearCurrentClient,
    clearCurrentUser,
    clearCurrentTicket,
    clearCurrentYear,
    clearCurrentTabs
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot));
}
